import React from 'react'
import { Link } from 'gatsby'
import styled, { css } from 'styled-components'
import {
  calculateResponsiveSize,
  calculateMobileResponsiveSize
} from 'styles/calculate-responsive-size'
import { mediaMax } from 'styles/media-queries'
import CloseIcon from 'icons/close.svg'
import instagramIcon from 'icons/instagram.svg'

export const MenuWrap = styled.div`
  height: 100%;
  width: ${calculateResponsiveSize(350)};
  position: fixed;
  background: var(--gray);
  color: #fff;
  top: 0;
  right: 0;
  z-index: 20;
  padding: ${calculateResponsiveSize(27)};
  gap: ${calculateResponsiveSize(28)};
  transform: translateX(100%);
  transition: 0.5s;
  overflow: auto;

  display: flex;
  flex-direction: column;
  ${mediaMax('mobile')} {
    width: 100%;
    padding: ${calculateMobileResponsiveSize(25)};
    height: 100%;
    gap: ${calculateMobileResponsiveSize(30)};
  }
`

export const Item = styled((props) => <Link {...props} />)`
  color: var(--black);
  font-family: 'FreightBig Pro';
  font-size: ${calculateResponsiveSize(35)};
  font-weight: 400;
  line-height: ${calculateResponsiveSize(35)};

  text-align: left;
  text-transform: uppercase;
  text-decoration: none;
  display: block;
  padding: 0;

  transition: 0.5s;
  &:hover {
    color: var(--beige);
  }
  &.secondary {
    font-size: ${calculateResponsiveSize(14)};
    letter-spacing: ${calculateResponsiveSize(2.8)};
  }

  ${mediaMax('mobile')} {
    font-size: ${calculateMobileResponsiveSize(35)};
    line-height: ${calculateMobileResponsiveSize(35)};
    letter-spacing: 0em;

    /*  &.secondary {
      font-size: 14px;
      line-height: 16px;
    } */
  }
`

export const Li = styled.li``
export const Overlay = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 19;
  visibility: hidden;

  background: #000;
  opacity: 0;
  transition: opacity 0.5s, visibility 0.5s;
`

export const Primary = styled.ul`
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  gap: ${calculateResponsiveSize(13)};

  margin-top: ${calculateResponsiveSize(100)};
  ${Li} {
  }

  ${mediaMax('mobile')} {
    margin-top: auto;
    gap: ${calculateMobileResponsiveSize(15)};
  }
`

export const Secondary = styled.ul`
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  gap: ${calculateResponsiveSize(4)};

  margin-bottom: auto;

  ${mediaMax('mobile')} {
    gap: ${calculateMobileResponsiveSize(4)};
  }
`

export const Wrapper = styled.div`
  z-index: 101;
  position: relative;
  &.open {
    ${MenuWrap} {
      transform: translateX(0);
    }
    ${Overlay} {
      opacity: 0;
      visibility: visible;
    }
  }

  ${mediaMax('mobile')} {
    &.open {
      ${MenuWrap} {
        transform: translateX(0);
      }
      ${Overlay} {
        display: none;
      }
    }
  }
`

export const Text = css`
  text-decoration: none;
  text-transform: uppercase;

  font-family: 'Davis Sans';
  font-size: ${calculateResponsiveSize(10)};
  font-weight: 400;
  line-height: ${calculateResponsiveSize(12)};
  letter-spacing: 0.2em;
  color: #000000;

  ${mediaMax('mobile')} {
    font-size: ${calculateMobileResponsiveSize(10)};
    line-height: ${calculateMobileResponsiveSize(12)};
  }
`
export const Links = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: ${calculateResponsiveSize(6)};

  ${mediaMax('mobile')} {
    gap: ${calculateMobileResponsiveSize(6)};
  }
`

export const LegalLink = styled((props) => <Link {...props} />)`
  ${Text}
  &:hover {
    color: var(--beige);
  }
  ${mediaMax('mobile')} {
    &:hover {
      color: #000000;
    }
  }
`
export const LinkItem = styled.a`
  ${Text}
  &:hover {
    color: var(--beige);
  }
  ${mediaMax('mobile')} {
    &:hover {
      color: #000000;
    }
  }
`
export const CloseButton = styled.button`
  cursor: pointer;
  background: none;
  border: none;
  display: flex;

  width: ${calculateResponsiveSize(20)};
  height: ${calculateResponsiveSize(20)};
  -webkit-mask: url(${CloseIcon}) no-repeat;
  -webkit-mask-size: contain;
  background: var(--black);

  position: absolute;
  right: ${calculateResponsiveSize(50)};
  top: ${calculateResponsiveSize(50)};
  ${mediaMax('mobile')} {
    width: ${calculateMobileResponsiveSize(30)};
    height: ${calculateMobileResponsiveSize(30)};

    right: ${calculateMobileResponsiveSize(25)};
    top: ${calculateMobileResponsiveSize(50)};
  }
`
export const Icon = styled.a`
  mask-position: center center;
  mask-repeat: no-repeat;
  mask-size: contain;

  -webkit-mask-position: center center;
  -webkit-mask-repeat: no-repeat;
  -webkit-mask-size: contain;

  width: ${calculateResponsiveSize(12)};
  height: ${calculateResponsiveSize(12)};
  display: block;
  cursor: pointer;
  background: var(--black);
  margin-top: ${calculateResponsiveSize(8)};

  mask-image: url(${instagramIcon});
  -webkit-mask-image: url(${instagramIcon});
  &:hover {
    background: var(--beige);
  }
  ${mediaMax('mobile')} {
    width: 12px;
    height: 12px;
    position: static;
    margin-top: 8px;
  }
`
