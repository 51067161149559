import { createGlobalStyle } from 'styled-components'
import {
  calculateResponsiveSize,
  calculateMobileResponsiveSize
} from 'styles/calculate-responsive-size'
import { mediaMax } from 'styles/media-queries'
import leavesImg from 'static/bg1.png'

const GlobalStyle = createGlobalStyle`
  :root {
    --beige: #F8F0E6;
    --white: #FFFFFF;
    --black: #000;
    --gray: #D6CEC7; 

  } 
  li {
    list-style: none;
  }
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  *::-webkit-scrollbar {
    width: 0;
  }
  *::-webkit-contacts-auto-fill-button {
    visibility: hidden;
    position: absolute;
    right: 0;
 }
  body {
    width: 100%;
    height: 100%;

    font-family: "Davis Sans";
    font-size: ${calculateResponsiveSize(16)};
    font-weight: 400;
    line-height: ${calculateResponsiveSize(22)};
    letter-spacing: 0.1em;
    
    color: var(--black);
    background: var(--beige);
    min-width: 320px;
    overflow-y: scroll;
    ${mediaMax('mobile')} {
      font-size: 12px;
      line-height: 18px;
    }
  }
  body.hidden {
    height: 100vh !important;
  }
  body.hidden .home-wrapper {
    height: 100vh;
    overflow: hidden;
  }
  .fadeIn {
    transform: translateY(50px);
    opacity: 0;
    transition: all 1s ease;
    &.visible {
      transform: translateY(0);
      opacity: 1;
    }
  }
  .parallax{
    position: relative;
  }
  .portal {
    position: fixed;
    inset: 0px;
    z-index: 106;
    display: flex;
    justify-content: center;
  }
  

  @-webkit-keyframes mouse-over {
    0% {
      background-size: 0% 100%;
      background-position: 0% 100%;

    }
    100% {
      background-size: 100% 100%;
      background-position: 0% 100%;

    }
  }
  @-webkit-keyframes mouse-out {
    0% {
      background-size: 100% 100%;
      background-position: 100% 0%;

    }
    100% {
      background-size: 0% 100%;
      background-position: 100% 0%;

    }
  }
  @keyframes fadeIn {
    0% {
      transform: translateY(-100%);
    }
    100% {
      transform: translateY(0%);
    }
  }
  .h2{
    font-family: "FreightBig Pro";
    font-size: ${calculateResponsiveSize(35)};
    font-weight: 400;
    line-height: ${calculateResponsiveSize(35)};
    text-transform: uppercase;
    white-space: break-spaces;
    letter-spacing: normal;
    
    ${mediaMax('mobile')} {
      font-size: ${calculateMobileResponsiveSize(28)};
      line-height: ${calculateMobileResponsiveSize(26)};
      white-space: normal;
    }
  }
  .h3{
    font-family: "FreightBig Pro";
    font-size: ${calculateResponsiveSize(28)};
    font-weight: 400;
    line-height: ${calculateResponsiveSize(28)};
    text-transform: uppercase;
    letter-spacing: normal;
    white-space: break-spaces;

    ${mediaMax('mobile')} {

      font-size: ${calculateMobileResponsiveSize(28)};
      line-height: ${calculateMobileResponsiveSize(26)};
    }

  }
  .body{
    font-family: "Davis Sans";
    font-size: ${calculateResponsiveSize(16)};
    font-weight: 400;
    line-height: ${calculateResponsiveSize(22)};
    white-space: break-spaces;
    letter-spacing: 0px;

    ${mediaMax('mobile')} {
      font-size: ${calculateMobileResponsiveSize(12)};
      line-height: ${calculateMobileResponsiveSize(16)};
      white-space: normal;

    }
  }
  .caption{
    font-family: "Davis Sans";
    font-size: ${calculateResponsiveSize(12)};
    font-weight: 700;
    line-height: ${calculateResponsiveSize(12)};
    letter-spacing: 0.32em;
    text-transform: uppercase;
    white-space: break-spaces;

    ${mediaMax('mobile')} {
      font-size: ${calculateMobileResponsiveSize(12)};
      line-height: ${calculateMobileResponsiveSize(12)};
      white-space: normal;
    }
  }
  .caption1{
    font-family: "Davis Sans";
    font-size: ${calculateResponsiveSize(12)};
    font-weight: 700;
    line-height: ${calculateResponsiveSize(25)};
    letter-spacing: 0.32em;
    text-transform: uppercase;
    white-space: break-spaces;
    ${mediaMax('mobile')} {
      font-size: ${calculateMobileResponsiveSize(12)};
      line-height: ${calculateMobileResponsiveSize(16)};
      white-space: normal;
    }
  }
  body .uwy.userway_p1 .userway_buttons_wrapper{
    bottom: ${calculateResponsiveSize(16)} !important;
    top: auto !important;
    right: ${calculateResponsiveSize(12)} !important;
    left: auto !important;
    transform: none !important;

    ${mediaMax('mobile')} {
      bottom: ${calculateMobileResponsiveSize(16)} !important;
      top: auto !important;
      right: ${calculateMobileResponsiveSize(12)} !important;
      left: auto !important;
      transform: none !important;
    }
  }
 /*  body .uwy.userway_p1 .userway_buttons_wrapper.show{
    display: block;
  } */
#userwayAccessibilityIcon{
  width: ${calculateResponsiveSize(50)};
  height: ${calculateResponsiveSize(50)};
  ${mediaMax('mobile')} {
    width: ${calculateMobileResponsiveSize(50)};
    height: ${calculateMobileResponsiveSize(50)};
  }
}
`

export default GlobalStyle
