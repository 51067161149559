import React, { useContext /* , useState */ } from 'react'
import { ThemeContext } from 'styles/theme-provider'

import Button from '../ui/button'
import MenuButton from './menu-button'

import { Wrap, Logo } from './index.styled'

const Header = () => {
  const { siteTheme, scrollSmoother } = useContext(ThemeContext)
  //  const [isScrolled, setIsScrolled] = useState(false)
  // let prevScrollPos

  /*   const handleScroll = () => {
    const currentScrollPos = window.pageYOffset
    if (currentScrollPos <= prevScrollPos) {
      setIsScrolled(false)
      //console.dir('back')
    } else {
      setIsScrolled(true)
      // console.dir('down')
    }

    prevScrollPos = currentScrollPos
  } */

  /*   useEffect(() => {
    prevScrollPos = window.pageYOffset
    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, []) */

  // click on logo
  const scrollToTop = () => {
    scrollSmoother.current.scrollTo(0, true)
  }

  return (
    <>
      <Wrap
        onClick={scrollToTop}
        className={['header', siteTheme].join(
          ' '
        )}
      >
        <Logo to='/#architecture' />
      </Wrap>

      <Wrap
        className={[
          'header',
          siteTheme,
          'headerRight'
          // isScrolled ? 'hide' : '',
        ].join(' ')}
      >
        <Button
          to='/contact'
          title='Inquire'
          className={[siteTheme, 'contact-btn'].join(' ')}
        />
        <MenuButton className={siteTheme} />
      </Wrap>
    </>
  )
}
export default Header
