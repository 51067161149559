import React, { useRef } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import Button from '../../ui/button'
import FooterSecondary from './footer-secondary'
import { ScrollSmoother } from 'gsap-wrapper'
import {
  Wrapper,
  MenuWrap,
  Item,
  Li,
  Overlay,
  Primary,
  Secondary,
  CloseButton,
  Icon
} from './index.styled'

const Menu = () => {
  const {
    dataJson: {
      footerInfo: { insta }
    },
    menuContent: { content: links }
  } = useStaticQuery(graphql`
    query Menu1Query {
      dataJson {
        footerInfo {
          insta
        }
      }
      menuContent {
        content {
          primary {
            text
            url
          }
          secondary {
            text
            url
          }
        }
      }
    }
  `)

  const menuRef = useRef(null)

  const onClose = () => {
    const scrollerSmoother = ScrollSmoother.create({
      content: '.home-content',
      wrapper: '.home-wrapper',
      smooth: 2,
      effects: true
    })

    menuRef.current.classList.remove('open')

    document.body.classList.remove('open')

    /* scrollerSmoother.paused(false) */
  }

  return (
    <Wrapper id='menu' ref={menuRef}>
      <MenuWrap>
        {/* <CloseButton onClick={onClose} /> */}
        <Primary>
          {links.primary.map((item, index) => (
            <Li key={`primary-item-${index}`} onClick={onClose}>
              <Item to={item.url}>{item.text}</Item>
            </Li>
          ))}
        </Primary>
        <Secondary>
          {links.secondary.map((item, index) => (
            <Li key={`secondary-item-${index}`} onClick={onClose}>
              <Button to={item.url} title={item.text} className='withHover' />
            </Li>
          ))}
          <Icon href={insta} target='_blank' />
        </Secondary>

        <FooterSecondary onClose={onClose} />
      </MenuWrap>
      <Overlay onClick={onClose} />
    </Wrapper>
  )
}

export default Menu
