import React from 'react'
import { Link } from 'gatsby'
import styled, { css } from 'styled-components'
import {
  calculateResponsiveSize,
  calculateMobileResponsiveSize
} from 'styles/calculate-responsive-size'
import { mediaMax } from 'styles/media-queries'
import FooterLogo from 'icons/logo-leaf.svg'

import instagramIcon from 'icons/instagram.svg'

export const Text = css`
  font-family: 'Davis Sans';
  font-size: ${calculateResponsiveSize(10)};
  font-weight: 300;
  line-height: ${calculateResponsiveSize(12)};
  letter-spacing: ${calculateResponsiveSize(2)};
  text-align: left;
  text-decoration: none;
  text-transform: uppercase;
  letter-spacing: 0.1em;

  color: var(--black);
  ${mediaMax('mobile')} {
    font-size: ${calculateMobileResponsiveSize(10)};
    line-height: ${calculateMobileResponsiveSize(12)};
    font-weight: 400;
  }
`
export const Logo = styled((props) => <Link {...props} />)`
  width: ${calculateResponsiveSize(40)};
  height: ${calculateResponsiveSize(24.45)};
  background: url(${FooterLogo}) no-repeat;
  background-size: contain !important;
  margin-bottom: ${calculateResponsiveSize(25)};
  transition: 0.5s;
  &:hover {
    opacity: 0.5;
  }
  &.click {
    opacity: 0;
  }
  ${mediaMax('mobile')} {
    width: 40px;
    height: 24.5px;
  }
`
export const LinkItem = styled.a`
  ${Text}
  color: #00000080;
  letter-spacing: 0.2em;
  ${mediaMax('mobile')} {
    font-size: ${calculateMobileResponsiveSize(9)};
    line-height: ${calculateMobileResponsiveSize(9)};
  }
`
export const LegalLink = styled((props) => <Link {...props} />)`
  ${Text}
  letter-spacing: 0.2em;
  color: #00000080;
  ${mediaMax('mobile')} {
    font-size: ${calculateMobileResponsiveSize(9)};
    line-height: ${calculateMobileResponsiveSize(9)};
  }
`
export const Contact = styled.div`
  ${Text}
  font-weight: 400;
  display: flex;
  align-items: center;

  gap: ${calculateResponsiveSize(30)};
  & a {
    text-decoration: none;
    color: var(--black);
  }
  ${mediaMax('mobile')} {
    font-weight: 400;

    gap: ${calculateMobileResponsiveSize(12)}
      ${calculateMobileResponsiveSize(46)};
    flex-wrap: wrap;
    justify-content: center;
    & a.phone {
      order: 3;
    }
  }
`

export const Icon = styled.a`
  mask-position: center center;
  mask-repeat: no-repeat;
  mask-size: contain;

  -webkit-mask-position: center center;
  -webkit-mask-repeat: no-repeat;
  -webkit-mask-size: contain;

  width: ${calculateResponsiveSize(12)};
  height: ${calculateResponsiveSize(12)};
  display: block;
  cursor: pointer;
  background: var(--black);
  position: absolute;
  bottom: ${calculateResponsiveSize(26)};
  right: ${calculateResponsiveSize(75)};

  mask-image: url(${instagramIcon});
  -webkit-mask-image: url(${instagramIcon});
  ${mediaMax('mobile')} {
    width: 12px;
    height: 12px;
    position: static;
  }
`

export const Links = styled.div`
  display: flex;
  align-items: center;
  gap: ${calculateResponsiveSize(30)};

  ${mediaMax('mobile')} {
    gap: 6px 24px;
    flex-wrap: wrap;
    justify-content: center;
  }
`

export const Wrapper = styled.footer`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${calculateResponsiveSize(137)} ${calculateResponsiveSize(60)}
    ${calculateResponsiveSize(23)};
  color: var(--black);
  gap: ${calculateResponsiveSize(11)};
  .nl {
    position: absolute;
    left: ${calculateResponsiveSize(50)};
    bottom: ${calculateResponsiveSize(20)};
    width: ${calculateResponsiveSize(88)};
  }
  ${mediaMax('mobile')} {
    padding: ${calculateMobileResponsiveSize(27)}
      ${calculateMobileResponsiveSize(0)} ${calculateMobileResponsiveSize(84)};
    gap: ${calculateMobileResponsiveSize(25)};
    .nl {
      position: static;
      width: ${calculateMobileResponsiveSize(88)};
    }
  }
`
export const Icons = styled.div`
  display: flex;
  align-items: center;
  gap: ${calculateMobileResponsiveSize(30)};
`
export const Copyright = styled.div`
  font-family: Davis Sans;
  font-size: ${calculateResponsiveSize(10)};
  font-weight: 400;
  line-height: ${calculateResponsiveSize(12)};
  letter-spacing: 0.1em;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #00000080;
  margin-top: ${calculateResponsiveSize(25)};
  ${mediaMax('mobile')} {
    font-size: ${calculateMobileResponsiveSize(10)};
    margin-top: ${calculateMobileResponsiveSize(25)};

    line-height: ${calculateMobileResponsiveSize(12)};
  }
`
